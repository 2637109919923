<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.order_payment") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                  :loading="loadingPrintButton"
                  :disabled="printShow"
                  @click="print()"
              >{{ $t("message.print") }}</el-button
              >
              <el-button @click="resetForm()">{{
                  $t("message.close")
                }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="grid-content">
      <div class="add_patient">
        <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            type="border-card"
        >
          <el-tab-pane :label="$t('message.order_payment')" name="first">
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t("message.order") }}:</td>
                <td># {{ order_payments.id }}</td>
              </tr>
              <tr>
                <td>{{ $t("message.total_amount") }}:</td>
                <td>{{ order_payments.total_price | formatMoney }}</td>
              </tr>
              <tr>
                <td>{{ $t("message.paid") }}:</td>
                <td>
                  {{
                    (payments.paid_price ? payments.paid_price : 0)
                        | formatMoney
                  }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("message.remaind") }}:</td>
                <td>
                  {{ (payments.paid_price
                    ? order_payments.total_price - payments.paid_price
                    : order_payments.total_price) | formatMoney }}
                </td>
              </tr>
              </tbody>
            </table>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.order_service')" name="second">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("message.name_service") }}</th>
                <th>{{ $t("message.price2") }}</th>
                <th>{{ $t("message.discount") }}</th>
                <th>{{ $t("message.code") }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(service, index) in orderServices" :key="index">
                <td>{{ service.id }}</td>
                <td>{{ service.name }}</td>
                <td>{{ service.total_price | formatMoney }}</td>
                <td>
                    <span v-if="service.has_percent">{{
                        service.discount_price + " %"
                      }}</span>
                  <span v-else>{{
                      service.price_of_discount | formatMoney
                    }}</span>
                </td>
                <td>{{ service.code }}</td>
                <td>
                  <el-button
                      :disabled="!order_payments.owner_branch"
                      round
                      type="danger"
                      @click="deleteServ(service.id)"
                  >{{ $t("message.delete") }}
                  </el-button>
                </td>
              </tr>
              </tbody>
            </table>
          </el-tab-pane>
          <el-tab-pane
              :label="$t('message.organization')"
              name="tree"
              v-if="order_payments.contract_id"
          >
            <table
                class="table table-striped table-bordered"
                v-if="order_payments.contract"
            >
              <thead>
              <tr>
                <th>{{ $t("message.organization") }}</th>
                <th>{{ $t("message.inn") }}</th>
                <th>{{ $t("message.aggrement") }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{
                    order_payments.contract.organization
                        ? order_payments.contract.organization.company_name
                        : ""
                  }}
                </td>
                <td>
                  {{
                    order_payments.contract.organization
                        ? order_payments.contract.organization.inn
                        : ""
                  }}
                </td>
                <td>
                  {{
                    order_payments.contract
                        ? order_payments.contract.number
                        : ""
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </el-tab-pane>
        </el-tabs>
        <div v-if="order_payments.owner_branch">
          <div class="mb-0 mt-4">
            <el-row class="button_l">
              <!-- <el-row
            class="button_l"
            v-if=" order_payments.total_price !=  (payments.paid_price ? payments.paid_price : 0)  "> -->
              <el-col :span="24">
                <el-button
                    :loading="statusUpdateDebt"
                    :type="
                    order_payments.payment_status == 'debt' ? 'primary' : 'info'
                  "
                    round
                    @click="statusUpdate('debt')"
                >{{ $t("message.lend") }}
                </el-button>
                <el-button
                    :loading="statusUpdateFreeCharge"
                    :type="
                    order_payments.payment_status == 'free_charge'
                      ? 'primary'
                      : 'info'
                  "
                    round
                    @click="statusUpdate('free_charge')"
                >{{ $t("message.without_amount") }}</el-button
                >
                <el-button
                    v-if="
                    order_payments.payment_status == 'free_charge' ||
                    order_payments.payment_status == 'debt'
                  "
                    type="danger"
                    :loading="statusUpdateCancel"
                    round
                    @click="statusUpdate('cancel')"
                >{{ $t("message.cancel") }}</el-button
                >

                <el-button
                    v-if="transactions.length"
                    style="float: right"
                    type="danger"
                    :loading="statusUpdateCancel"
                    round
                    @click="dialogVisible = true"
                >{{ $t("message.back") }}</el-button
                >
              </el-col>
            </el-row>
          </div>

          <div
              v-if="!isDebt(order_payments.payment_status)"
              class="grid-content formaner mt-3"
              style="padding: 10px 0px !important"
          >
            <i class="el-icon-s-grid mb-3"></i>
            {{ $t("message.add_transaction") }}
            <el-form
                ref="formTransaction"
                :model="form"
                :rules="rules"
                size="mini"
                @keyup.enter.native="submitTransaction"
            >
              <el-row :gutter="24">
                <el-col :span="4">
                  <el-form-item
                      :label="columns.payment_type_id.title"
                      prop="payment_type_id"
                  >
                    <el-select
                        v-model="form.payment_type_id"
                        filterable
                        :placeholder="columns.payment_type_id.title"
                    >
                      <el-option
                          v-for="item in paymentTypes"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="5">
                  <el-form-item :label="columns.price.title" prop="price">
                    <el-input
                        v-model="form.price"
                        :placeholder="columns.price.title"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="5">
                  <el-form-item :label="columns.date.title" prop="date">
                    <el-date-picker
                        type="date"
                        v-model="form.date"
                        :format="'dd.MM.yyyy'"
                        :value-format="'dd.MM.yyyy'"
                        :placeholder="columns.date.title"
                        :picker-options="datePickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :span="5">
                  <el-form-item :label="columns.comment.title" prop="comment">
                    <el-input
                        v-model="form.comment"
                        :placeholder="columns.comment.title"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="5">
                  <el-button
                      :loading="loadingButton"
                      :disabled="(loadingButton) || ((payments.paid_price
                        ? order_payments.total_price - payments.paid_price
                        : order_payments.total_price) === 0)"
                      @click="submitTransaction()"
                      type="success"
                  >{{ $t("message.add_transaction") }}</el-button
                  >
                </el-col>
              </el-row>
            </el-form>
          </div>

          <i class="el-icon-s-grid"></i> {{ $t("message.history_transaction") }}
          <div class="bg-dark"></div>
          <el-table
              :data="transactions"
              style="width: 100%"
              max-height="300"
              :row-class-name="tableRowClassName"
          >
            <el-table-column
                prop="payment_type.name"
                :label="$t('message.payment_type')"
            >
            </el-table-column>

            <el-table-column prop="price" :label="$t('message.price2')">
            </el-table-column>
            <el-table-column
                prop="currency.name"
                :label="$t('message.currency')"
            >
            </el-table-column>
            <el-table-column prop="rate" :label="$t('message.rate')">
            </el-table-column>
            <el-table-column :label="$t('message.date_0')">
              <template slot-scope="scope">
                <span>{{ scope.row.date ? scope.row.date : "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="comment" :label="$t('message.comment')">
            </el-table-column>
            <el-table-column :label="$t('message.user')">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.user ? scope.row.user.name : " " }}
                  {{ scope.row.user ? scope.row.user.surname : "" }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="created_at" :label="$t('message.date')">
              <template slot-scope="scope">
                <span>{{ scope.row.created_at }}</span>
              </template>
            </el-table-column>

            <el-table-column width="180">
              <template slot-scope="scope">
                <div class="d-flex style_mioni_btu">
                  <el-button type="text" @click="deleteRow(scope.row.id)">{{
                    $t("message.delete")
                  }}</el-button>

                  <el-dialog
                    :title="$t('message.back')"
                    :visible.sync="dialogVisible"
                    width="50%"
                    :append-to-body="true"
                  >
                    <el-form
                      ref="formRefund"
                      :model="refund_form"
                      :rules="rules"
                      size="mini"
                      @keyup.enter.native="submitTransaction"
                    >
                      <el-row :gutter="36">
                        <el-col :span="6">
                          <el-form-item
                            :label="columns.payment_type_id.title"
                            prop="payment_type_id"
                          >
                            <el-select
                              v-model="refund_form.refund_type_id"
                              filterable
                              :placeholder="columns.payment_type_id.title"
                            >
                              <el-option
                                v-for="item in paymentTypes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>

                        <el-col :span="6">
                          <el-form-item :label="columns.price.title">
                            <el-input
                              v-model="refund_form.price"
                              :placeholder="columns.price.title"
                            ></el-input>
                          </el-form-item>
                        </el-col>

                        <el-col :span="6">
                          <el-form-item :label="columns.date.title">
                            <el-date-picker
                              type="date"
                              v-model="refund_form.date"
                              :format="'dd.MM.yyyy'"
                              :value-format="'dd.MM.yyyy'"
                              :placeholder="columns.date.title"
                              :picker-options="datePickerOptions"
                            ></el-date-picker>
                          </el-form-item>
                        </el-col>

                        <el-col :span="6">
                          <el-form-item
                            :label="columns.comment.title"
                            prop="comment"
                          >
                            <el-input
                              v-model="refund_form.comment"
                              :placeholder="columns.comment.title"
                            ></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>

                      <el-button
                        type="success"
                        class="px-3"
                        style="margin-left: 40%; padding: 12px !important"
                        @click.native.prevent="updateRefund()"
                        :loading="loadingRefundButton"
                      >
                        <i class="el-icon-refresh-right"></i>
                        {{ $t("message.back") }}
                      </el-button>
                    </el-form>
                  </el-dialog>

                  <el-button
                    style="margin: 0 auto; display: none"
                    class="mt-1"
                    type="danger"
                    :disabled="scope.row.refund ? true : false"
                    @click.native.prevent="
                      deleteRow(scope.row.id, scope.$index, transactions)
                    "
                    size="small"
                  >
                    <i class="el-icon-delete"></i>
                    {{ $t("message.delete") }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
import CrmCreate from "./crm-create";
export default {
  components: {
    CrmCreate,
  },
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      loadingRefundButton: false,
      loadingButton: false,
      statusUpdateDebt: false,
      statusUpdateFreeCharge: false,
      statusUpdateCancel: false,
      drawerCreate: false,
      reopenUpdate: false,
      dialogVisible: false,
      order_payments: [],
      payments: [],
      orderServices: [],
      transactions: [],
      activeName: "first",
      form: {
        date: new Date(),
      },
      refund_form: {
        date: new Date(),
      },
      loadingPrintButton: false,
      printShow: false,
      datePickerOptions: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 8.64e7 * 2;
        },
      },
      refundScope: null,
      total_price: null,
      paid_price: null,
      remaining_price: null,
    };
  },
  computed: {
    ...mapGetters({
      currency: "currencies/list",
      paymentTypes: "paymentTypes/list",
      model: "payment/order_payments",
      columns: "transaction/columns",
      rules: "transaction/rules",
    }),
  },
  watch: {
    model: {
      handler: function () {
        this.order_payments = JSON.parse(JSON.stringify(this.model));
        this.payments =
          typeof this.model.payments[0] !== "undefined"
            ? JSON.parse(JSON.stringify(this.model.payments[0]))
            : [];
        this.orderServices =
          typeof this.model.order_services !== "undefined"
            ? JSON.parse(JSON.stringify(this.model.order_services))
            : [];
        this.transactions =
          typeof this.model.payments[0] !== "undefined"
            ? JSON.parse(JSON.stringify(this.model.payments[0].transactions))
            : [];
      },
      deep: true,
    },
  },
  created() {
    this.currenciesList();
    this.paymentTypesList();
    this.order_payments = JSON.parse(JSON.stringify(this.model));
    this.payments =
      typeof this.model.payments[0] !== "undefined"
        ? JSON.parse(JSON.stringify(this.model.payments[0]))
        : [];
    this.orderServices =
      typeof this.model.order_services !== "undefined"
        ? JSON.parse(JSON.stringify(this.model.order_services))
        : [];
    this.transactions =
      typeof this.model.payments[0] !== "undefined"
        ? JSON.parse(JSON.stringify(this.model.payments[0].transactions))
        : [];
    this.form.date = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0');
    // var yyyy = today.getFullYear();
    // today = yyyy + '-' + mm + '-' + dd;
    // this.form.date = today;
  },
  mounted() {},
  methods: {
    ...mapActions({
      save: "payment/paymentStore",
      currenciesList: "currencies/index",
      paymentTypesList: "paymentTypes/index",
      delTransaction: "transaction/remove",
      updatetTransactionRefund: "transaction/refund",
      showModel: "payment/relationShow",
      updateStatus: "payment/updateStatus",
      miniPrint: "orders/miniPrint",
      deleteService: "orders/deleteService",
    }),
    handleClick(tab, event) {},
    fetchData() {
      const query = { id: this.order_payments.id, relation: true };
      this.showModel(query)
        .then((res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    deleteServ(id) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteService({
            order_id: this.order_payments.id,
            service_id: id,
          })
            .then((res) => {
              this.fetchData();
              this.loadModel();
              this.$parent.$parent.listChanged();
              this.$notify({
                title: this.$t("message.status_closed"),
                type: "success",
                offset: 130,
                dangerouslyUseHTMLString: true,
                message: res.message,
              });
            })
            .catch((err) => {
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.refund == true) {
        return " text-dark bg-opacity-10 bg-danger";
      }
      return "";
    },
    loadModel() {
      this.order_payments = JSON.parse(JSON.stringify(this.model));
      this.payments =
        typeof this.model.payments[0] !== "undefined"
          ? JSON.parse(JSON.stringify(this.model.payments[0]))
          : [];
      this.orderServices =
        typeof this.model.order_services !== "undefined"
          ? JSON.parse(JSON.stringify(this.model.order_services))
          : [];
      this.transactions =
        typeof this.model.payments[0] !== "undefined"
          ? JSON.parse(JSON.stringify(this.model.payments[0].transactions))
          : [];
    },
    isDebt(status) {
      return status === "debt";
    },
    statusUpdate(status) {
      if (status == "free_charge") this.statusUpdateFreeCharge = true;
      if (status == "debt") this.statusUpdateDebt = true;
      if (status == "cancel") this.statusUpdateCancel = true;

      let paid_price = this.payments.paid_price ? this.payments.paid_price : 0;
      let payments =
          paid_price == this.order_payments.total_price ? "paid" : "unpaid";

      const query = {
        id: this.order_payments.id,
        status: status,
        payments: payments,
        relation: true,
      };

      this.updateStatus(query)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.statusUpdateFreeCharge = false;
          this.statusUpdateDebt = false;
          this.statusUpdateCancel = false;
          this.$alert(res.message);
          this.fetchData();
        })
        .catch((err) => {
          this.statusUpdateFreeCharge = false;
          this.statusUpdateDebt = false;
          this.statusUpdateCancel = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    submitTransaction() {
      this.$refs["formTransaction"].validate((valid) => {
        if (valid) {
          this.saveAction(true);
        }
      });
    },
    saveAction(close) {
      let price =
        typeof this.order_payments.payments[0] != "undefined"
          ? this.order_payments.payments[0].paid_price
          : 0;
      let val = this.order_payments.total_price - price;
      let remaining = this.payments.paid_price ? this.order_payments.total_price - this.payments.paid_price : this.order_payments.total_price;
      if (val == NaN && val < 0) {
        this.$notify({
          title: this.$t("message.validation"),
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: i18n.t("message.overpaid") + " " + val,
        });
        return;
      }
      if (remaining < this.form.price) {
        let dd = this.form.price - remaining;
        this.$notify({
          title: this.$t("message.validation"),
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: i18n.t("message.overpaid_amount") + " " + dd,
        });
        return;
      }
      if (this.form.price <= 0) {
        this.$notify({
          title: "Внимание ",
          type: "warning",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: i18n.t("message.set_transaction_amount"),
        });
        return;
      }
      this.form.paymentable_id = this.order_payments.id;
      this.form.paymentable_type = "App\\Order";
      this.form.total_price = this.order_payments.total_price;
      this.form.debit = 1;
      this.form.rate = 1;
      this.loadingButton = true;
      this.save(this.form)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.loadingButton = false;

          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            dangerouslyUseHTMLString: true,
            message: res.message,
          });

          this.fetchData();
          this.form = {
            date: new Date(),
          };
          if (close) {
            this.$refs["formTransaction"].resetFields();
          }
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    updateRefund() {
      let paid_price =
        typeof this.order_payments.payments[0] != "undefined"
          ? this.order_payments.payments[0].paid_price
          : 0;

      if (paid_price <= 0) {
        this.$notify({
          title: this.$t("message.validation"),
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: i18n.t("message.not_paid_of_all") + " " + paid_price,
        });
        return;
      }
      if (parseFloat(paid_price) < this.refund_form.price) {
        let dd = this.refund_form.price - paid_price;
        this.$notify({
          title: this.$t("message.validation"),
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: i18n.t("message.overpaid_amount") + " " + dd,
        });
        return;
      }

      this.refund_form.order_id = this.order_payments.id;
      this.refund_form.paymentable_id = this.order_payments.id;
      this.refund_form.paymentable_type = "App\\Order";
      this.refund_form.debit = 1;
      this.refund_form.rate = 1;

      this.loadingRefundButton = true;
      this.updatetTransactionRefund(this.refund_form)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.loadingRefundButton = false;
          this.$notify({
            title: this.$t("message.status_closed"),
            type: "success",
            offset: 130,
            dangerouslyUseHTMLString: true,
            message: res.message,
          });
          this.fetchData();
          this.$refs["formRefund"].resetFields();
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.loadingRefundButton = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },

    deleteRow(id, index, transactions) {
      let data = { transaction_id: id };
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.delTransaction(data)
            .then((res) => {
              this.$parent.$parent.listChanged();
              this.$alert(res.message);
              this.fetchData();
            })
            .catch((err) => {
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawerUpdate", reload: true });
    },
    closeDrawer(obj) {
      if (obj.reload) {
        this.fetchData();
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    print() {
      let vm = this;
      var jqxhr = $.get(
        process.env.VUE_APP_URL +
          `orders/mini-print?id=${this.order_payments.id}`,
        function () {}
      )
        .done(function (res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function () {
          alert(i18n.t("message.cannot_print"));
          vm.waitingPrint = false;
        });
    },
  },
};
</script>

<style>
.el-table .warning-row-color {
  background-color: "red";
}

.el-table .success-row {
  background: "rgb(100, 230, 190)";
}

.el-table .other-row {
  background: "rgb(100, 230, 190)";
}

.order_payment_table {
  align-content: center;
  border: 1px solid #ccc;
}

.order_payment_table tr {
  border: 1px solid #ccc;
}

.formaner .el-button {
  width: 100%;
  margin-top: 21px;
}

.formaner {
  border-bottom: 1px solid #e4e7ed;
  /* border-top: 1px solid #e4e7ed; */
  margin: 0 0 20px 0;
  /* padding: 20px 0px !important */
}

.style_mioni_btu .el-button {
  margin: 0px 5px !important;
  padding: 6px !important;
  height: 28px;
}
</style>
